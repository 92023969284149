import React, { useState } from 'react';
import InventarioService from 'src/modules/inventario/inventarioService';
import InventarioFormModal from 'src/view/inventario/form/InventarioFormModal';
import AutocompleteInMemoryFormItem from 'src/view/shared/form/items/AutocompleteInMemoryFormItem';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import selectors from 'src/modules/inventario/inventarioSelectors';

function InventarioAutocompleteFormItem(props) {
  const { setValue, getValues } = useFormContext();

  const [modalVisible, setModalVisible] = useState(false);

  const hasPermissionToCreate = useSelector(
    selectors.selectPermissionToCreate,
  );

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const doCreateSuccess = (record) => {
    const { name, mode } = props;

    if (mode && mode === 'multiple') {
      setValue(
        name,
        [...(getValues()[name] || []), record],
        { shouldValidate: true, shouldDirty: true },
      );
    } else {
      setValue(name, record, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    doCloseModal();
  };

  const fetchFn = async (value, limit) => {
    try {
      // Fetch all inventory items
      const results = await InventarioService.listAutocomplete(value, limit);
      console.log('Resultados de inventario obtenidos:', results.length);
      
      // If tipoActivoId is provided, filter the results by tipo de activo
      if (props.tipoActivoId) {
        console.log(`Filtrando inventario por tipoActivoId: ${props.tipoActivoId} (tipo: ${typeof props.tipoActivoId})`);
        
        const filteredResults = results.filter((item) => {
          // Check if the item has activo and tipoActivo
          if (!item.record || !item.record.activo || !item.record.activo.tipoActivo) {
            console.log(`Elemento ${item.value} no tiene activo o tipoActivo`);
            return false;
          }
          
          const tiposActivo = item.record.activo.tipoActivo;
          const tipoActivoIdStr = String(props.tipoActivoId);
          
          // Check if any of the tipoActivo entries match the selected tipoActivoId
          const matches = tiposActivo.some((tipo) => {
            if (typeof tipo === 'object' && tipo !== null) {
              const match = String(tipo.id) === tipoActivoIdStr;
              if (match) console.log(`Objeto tipo ${tipo.id} coincide con ${tipoActivoIdStr}`);
              return match;
            }
            const match = String(tipo) === tipoActivoIdStr;
            if (match) console.log(`Tipo string ${tipo} coincide con ${tipoActivoIdStr}`);
            return match;
          });
          
          if (matches) {
            console.log(`Elemento ${item.value} (${item.label}) coincide con tipoActivoId ${tipoActivoIdStr}`);
          }
          
          return matches;
        });
        
        console.log(`Filtrado completado: ${filteredResults.length} resultados coinciden con el tipo de activo ${props.tipoActivoId}`);
        return filteredResults;
      }
      
      return results;
    } catch (error) {
      console.error('Error al obtener inventario:', error);
      return [];
    }
  };

  const mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return null;
      }

      const value = originalValue.id;
      let label = originalValue.label;

      if (originalValue.descripcion) {
        label = originalValue.descripcion;
      }

      return {
        key: value,
        value,
        label,
        ...originalValue.record,
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return null;
      }

      return {
        id: originalValue.value,
        label: originalValue.label,
        ...originalValue,
      };
    },
  };

  // Add a key prop that changes when tipoActivoId changes to force re-render
  const key = `inventario-autocomplete-${props.tipoActivoId || 'all'}`;

  return (
    <>
      <AutocompleteInMemoryFormItem
        {...props}
        entity="inventario"
        fetchFn={fetchFn}
        mapper={mapper}
        onOpenModal={doOpenModal}
        hasPermissionToCreate={hasPermissionToCreate}
        key={key}
      />

      {modalVisible && (
        <InventarioFormModal
          onClose={doCloseModal}
          onSuccess={doCreateSuccess}
        />
      )}
    </>
  );
}

export default InventarioAutocompleteFormItem;
