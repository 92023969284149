import {Box} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import InventarioListItem from 'src/view/inventario/list/InventarioListItem';
import PuestoListItem from 'src/view/puesto/list/PuestoListItem';
import ServicioListItem from 'src/view/servicio/list/ServicioListItem';

export default function RecursoCardAlignItemsList(props) {
  const {record} = props;
  console.log('RECORD', record);

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'rgb(18, 18, 18)',
        borderRadius: 2,
      }}>
      {record?.tipo === 'Inventario' && (
        <React.Fragment>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Box>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <InventarioListItem type={'avatar'} value={record?.repuesto?.record} />
                </ListItemAvatar>
                <ListItemText
                  sx={{color: 'white'}}
                  primary={`$ ${new Intl.NumberFormat('es-CL', {
                    currency: 'CLP',
                    style: 'decimal',
                  }).format(record?.total)}`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: 'inline',
                          color: 'rgba(255, 255, 255, 0.7)',
                        }}
                        component="span"
                        variant="body1"
                        color="text.secondary">
                        {`${record?.repuesto?.record?.activo?.nombre || 0} x ${record?.cantidad || 0}`}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem sx={{color: 'white', backgroundColor: 'gray'}} />
            <Box sx={{color: 'white', marginLeft: '24px', marginBottom: '-3px'}}>
              <div style={{fontSize: '0.8em'}}>SUBTOTAL</div>
              <div style={{}}>
                {'$ '}
                {new Intl.NumberFormat('es-CL', {
                  currency: 'CLP',
                  style: 'decimal',
                }).format(record?.cantidad * record?.total)}
              </div>
            </Box>
          </Box>
        </React.Fragment>
      )}

      {record?.tipo === 'Recurso Humano' && (
        <React.Fragment>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Box>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <PuestoListItem type={'avatar'} value={record?.puesto} />
                </ListItemAvatar>
                <ListItemText
                  sx={{color: 'white'}}
                  primary={`$ ${new Intl.NumberFormat('es-CL', {
                    currency: 'CLP',
                    style: 'decimal',
                  }).format(record?.total)}`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: 'inline',
                          color: 'rgba(255, 255, 255, 0.7)',
                        }}
                        component="span"
                        variant="body1"
                        color="text.secondary">
                        {`${record?.puesto?.label || record?.puesto?.descripcion} x ${record?.cantidad} HH`}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem sx={{color: 'white', backgroundColor: 'gray'}} />
            <Box sx={{color: 'white', marginLeft: '24px', marginBottom: '-3px'}}>
              <div style={{fontSize: '0.8em', color: 'lightgray'}}>SUBTOTAL</div>
              <div style={{}}>
                {'$ '}
                {new Intl.NumberFormat('es-CL', {
                  currency: 'CLP',
                  style: 'decimal',
                }).format(record?.cantidad * record?.total)}
              </div>
            </Box>
          </Box>
        </React.Fragment>
      )}

      {record?.tipo === 'Servicios' && (
        <React.Fragment>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Box>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <ServicioListItem type={'avatar'} value={record?.servicio?.record} />
                </ListItemAvatar>
                <ListItemText
                  sx={{color: 'white'}}
                  primary={`$ ${new Intl.NumberFormat('es-CL', {
                    currency: 'CLP',
                    style: 'decimal',
                  }).format(record?.total)}`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: 'inline',
                          color: 'rgba(255, 255, 255, 0.7)',
                        }}
                        component="span"
                        variant="body1"
                        color="text.secondary">
                        {`${record?.descripcion} x ${record?.cantidad || 0} HH`}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem sx={{color: 'white', backgroundColor: 'gray'}} />
            <Box sx={{color: 'white', marginLeft: '24px', marginBottom: '-3px'}}>
              <div style={{fontSize: '0.8em'}}>SUBTOTAL</div>
              <div style={{}}>
                {'$ '}
                {new Intl.NumberFormat('es-CL', {
                  currency: 'CLP',
                  style: 'decimal',
                }).format(record?.cantidad * record?.total)}
              </div>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </List>
  );
}
