import {Add, Delete} from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {i18n} from 'src/i18n';
import recursoEnumerators from 'src/modules/recurso/recursoEnumerators';
import TipoActivoService from 'src/modules/tipoActivo/tipoActivoService';
import RecursoAutocompleteFormItem from 'src/view/recurso/autocomplete/RecursoAutocompleteFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';

function TareaResourcesForm(props) {
  const {disabled = false} = props;
  const {setValue, getValues, control} = useFormContext();
  const [selectedTipo, setSelectedTipo] = useState('');
  // Asegurarse de que selectedTipoActivoId siempre tiene un valor definido (string vacío para "Todos")
  const [selectedTipoActivoId, setSelectedTipoActivoId] = useState('');
  const [tiposActivo, setTiposActivo] = useState([]);
  const [loadingTiposActivo, setLoadingTiposActivo] = useState(false);

  // Asegurarse que resourcesList existe en el formulario principal
  const initializeResources = () => {
    const currentResources = getValues('resourcesList');
    if (!currentResources) {
      setValue('resourcesList', [], {shouldDirty: true});
    }
  };

  // Inicializar recursos al montar el componente
  useEffect(() => {
    initializeResources();
  }, []);

  // Cargar tipos de activo cuando se selecciona Inventario
  useEffect(() => {
    if (selectedTipo === 'Inventario') {
      loadTiposActivo();
    } else {
      // Asegurarse de establecer un valor definido (string vacío)
      setSelectedTipoActivoId('');
    }
  }, [selectedTipo]);

  // Cargar los tipos de activo desde el servidor
  const loadTiposActivo = async () => {
    try {
      setLoadingTiposActivo(true);
      const response = await TipoActivoService.listAutocomplete('', 100);
      console.log('Tipos de activo cargados:', response);

      // Asegurarse de que todos los valores sean strings para evitar problemas de tipo
      const processedResponse = response.map((item) => ({
        ...item,
        value: String(item.value), // Convertir value a string para asegurar consistencia
      }));

      console.log('Tipos de activo procesados:', processedResponse);
      setTiposActivo(processedResponse);
    } catch (error) {
      console.error('Error al cargar tipos de activo:', error);
      // Si hay error, asegurar que tiposActivo sea al menos un array vacío
      setTiposActivo([]);
    } finally {
      setLoadingTiposActivo(false);
    }
  };

  const {
    fields: resourcesList,
    append: appendResource,
    remove: removeResource,
  } = useFieldArray({
    control,
    name: 'resourcesList',
  });

  const handleAddResource = () => {
    appendResource({
      recurso: null,
      cantidad: 1,
      tipoRecurso: selectedTipo,
      // Si no es Inventario o no hay selección, usamos string vacía (representa "Todos")
      // Asegurarse de que tipoActivoId siempre sea un string
      tipoActivoId: selectedTipo === 'Inventario' ? String(selectedTipoActivoId || '') : '',
    });
  };

  // Log para depuración
  console.log('Estado actual del componente:', {
    selectedTipo,
    selectedTipoActivoId,
    tiposActivoLength: tiposActivo.length,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{i18n('entities.tarea.resourcesList.title')}</Typography>
      </Grid>

      <Grid item xs={12} container spacing={2} alignItems="center">
        <Grid item xs={selectedTipo === 'Inventario' ? 4 : 8}>
          <FormControl fullWidth>
            <InputLabel id="tipo-recurso-label">Tipo de Recurso</InputLabel>
            <Select
              labelId="tipo-recurso-label"
              value={selectedTipo}
              onChange={(e) => setSelectedTipo(e.target.value)}
              label="Tipo de Recurso"
              disabled={disabled}>
              <MenuItem value="">Todos</MenuItem>
              {recursoEnumerators.tipo.map((tipo) => (
                <MenuItem key={tipo} value={tipo}>
                  {tipo}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Selector de Tipo de Activo - solo visible cuando el tipo es Inventario */}
        {selectedTipo === 'Inventario' && (
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="tipo-activo-label">Tipo de Activo</InputLabel>
              <Select
                labelId="tipo-activo-label"
                id="tipo-activo-select"
                value={selectedTipoActivoId || ''}
                onChange={(event) => {
                  // Extraer el valor manualmente del evento para evitar problemas con undefined
                  // Imprimir el evento completo para depuración
                  console.log('Evento de cambio:', event);
                  console.log('Valor del evento:', event.target.value);

                  // Asegurarse de que el valor nunca sea undefined
                  const newValue = event.target.value === undefined ? '' : event.target.value;
                  console.log('Tipo Activo seleccionado:', newValue, typeof newValue);

                  // Actualizar el estado con el nuevo valor
                  setSelectedTipoActivoId(String(newValue));
                }}
                label="Tipo de Activo"
                displayEmpty
                disabled={disabled || loadingTiposActivo}>
                <MenuItem value="">Todos</MenuItem>
                {tiposActivo.map((tipoActivo, index) => {
                  // Garantizar que cada clave sea única usando el índice como fallback
                  const itemKey = `tipoActivo-${tipoActivo.id || index}`;
                  return (
                    <MenuItem key={itemKey} value={tipoActivo.id || ''}>
                      {tipoActivo.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={selectedTipo === 'Inventario' ? 4 : 4}>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddResource} disabled={disabled}>
            {i18n('entities.tarea.resourcesList.add')}
          </Button>
        </Grid>
      </Grid>

      {resourcesList && resourcesList.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell>{i18n('entities.tarea.resourcesList.resource')}</TableCell>
                  <TableCell>{i18n('entities.tarea.resourcesList.quantity')}</TableCell>
                  <TableCell align="right">{i18n('entities.tarea.resourcesList.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resourcesList.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.tipoRecurso || 'Todos'}</TableCell>
                    <TableCell>
                      <RecursoAutocompleteFormItem
                        name={`resourcesList[${index}].recurso`}
                        label=""
                        required={true}
                        disabled={disabled}
                        tipoRecurso={item.tipoRecurso}
                        tipoActivoId={item.tipoActivoId}
                      />
                    </TableCell>
                    <TableCell>
                      <InputNumberFormItem
                        name={`resourcesList[${index}].cantidad`}
                        label=""
                        required={true}
                        min={1}
                        disabled={disabled}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => removeResource(index)} disabled={disabled}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
}

export default TareaResourcesForm;
