import React from 'react';
import Spinner from 'src/view/shared/Spinner';
import PuestoFormPage from '../form/PuestoFormPage';

function PuestoView(props) {
  const renderView = () => {
    const {record, drawer} = props;
    return <PuestoFormPage record={record} disabled={true} drawer={drawer} contractorsView={props.contractorsView} />;
  };

  const {record, loading} = props;

  if (loading || !record) {
    return <Spinner />;
  }

  return renderView();
}

export default PuestoView;
