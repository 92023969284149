import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import selectors from 'src/modules/recurso/recursoSelectors';
import RecursoService from 'src/modules/recurso/recursoService';
import RecursoFormModal from 'src/view/recurso/form/RecursoFormModal';
import AutocompleteInMemoryFormItem from 'src/view/shared/form/items/AutocompleteInMemoryFormItem';

function RecursoAutocompleteFormItem(props) {
  const {setValue, getValues} = useFormContext();
  // Asegurarse de que tipoActivoId siempre sea un string (o string vacío)
  const tipoRecurso = props.tipoRecurso;
  const tipoActivoId = props.tipoActivoId === undefined || props.tipoActivoId === null ? '' : String(props.tipoActivoId);

  const [modalVisible, setModalVisible] = useState(false);

  const hasPermissionToCreate = useSelector(selectors.selectPermissionToCreate);

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const doCreateSuccess = (record) => {
    const {name, mode} = props;

    if (mode && mode === 'multiple') {
      let newValues = [...(getValues()[name] || []), record];
      setValue(name, newValues, {shouldValidate: true, shouldDirty: true});
      props.onChange && props.onChange(newValues);
    } else {
      setValue(name, record, {
        shouldValidate: true,
        shouldDirty: true,
      });
      props.onChange && props.onChange(record);
    }

    doCloseModal();
  };

  const fetchFn = async (value, limit) => {
    try {
      // Traer todos los recursos (o un límite alto si hay muchos)
      const results = await RecursoService.listAutocomplete(value, null);

      // Crear una función para filtrar los resultados según los criterios
      const filterResults = (results) => {
        let filteredResults = [...results];

        // Filtrar por tipo de recurso si está especificado
        if (tipoRecurso) {
          filteredResults = filteredResults.filter((recurso) => recurso.record && recurso.record.tipo === tipoRecurso);
        }

        // Si es Inventario y hay un tipoActivoId seleccionado (y no es la opción "Todos"), filtrar por ese tipo de activo
        if (tipoRecurso === 'Inventario' && tipoActivoId && tipoActivoId !== '') {
          console.log(`Filtrando por tipoActivoId: ${tipoActivoId} (${typeof tipoActivoId})`);
          console.log('Resultados antes del filtro:', filteredResults);

          filteredResults = filteredResults.filter((recurso) => {
            // Verificar que el recurso tiene un activo y que ese activo tiene el tipo de activo seleccionado
            if (!recurso.record || !recurso.record.repuesto || !recurso.record.repuesto.activo) {
              return false;
            }

            // Verificar si el activo tiene el tipoActivoId en su array de tipoActivo
            const activoTipos = recurso.record.repuesto.activo.tipoActivo || [];

            // Asegurarse de que siempre comparamos strings
            const tipoActivoIdStr = String(tipoActivoId);

            return activoTipos.some((tipo) => {
              if (typeof tipo === 'object' && tipo !== null) {
                return String(tipo.id) === tipoActivoIdStr;
              }
              return String(tipo) === tipoActivoIdStr;
            });
          });
        }

        return filteredResults;
      };

      // Aplicar los filtros
      return filterResults(results);
    } catch (error) {
      console.error('Error al obtener recursos:', error);
      return [];
    }
  };

  const mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return null;
      }

      const value = originalValue.id;
      let label = originalValue.label;

      if (originalValue.descripcion) {
        label = originalValue.descripcion;
      }

      return {
        key: value,
        value,
        label,
        ...originalValue.record,
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return null;
      }

      return {
        id: originalValue.value,
        label: originalValue.label,
        ...originalValue,
      };
    },
  };

  return (
    <>
      <AutocompleteInMemoryFormItem
        {...props}
        fetchFn={fetchFn}
        mapper={mapper}
        onOpenModal={doOpenModal}
        hasPermissionToCreate={hasPermissionToCreate}
      />

      {modalVisible && <RecursoFormModal onClose={doCloseModal} onSuccess={doCreateSuccess} />}
    </>
  );
}

export default RecursoAutocompleteFormItem;
