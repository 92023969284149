import {Avatar, Chip, Tooltip} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {getHistory} from 'src/modules/store';
import ubicacionSelectors from 'src/modules/ubicacion/ubicacionSelectors';

function ChipPuestoView(props) {
  const {record, type, size} = props;

  const hasPermissionToRead = useSelector(ubicacionSelectors.selectPermissionToRead);

  const redirectTo = () => {
    if (hasPermissionToRead) {
      getHistory().push(`/puesto/` + record.id);
    }
  };

  const photoUrl = record?.nombre;

  const label = record?.nombre || record?.label;

  if (type === 'chip') {
    return (
      <Tooltip followCursor title={label}>
        <Chip
          avatar={props.contractorsView ? undefined : <Avatar src={photoUrl || ''} alt={label} />}
          label={label}
          onClick={() => console.log('onClick')}
          variant="outlined"
          color="primary"
        />
      </Tooltip>
    );
  }

  if (type === 'avatar') {
    return (
      <Tooltip followCursor title={label}>
        <Avatar onClick={() => console.log('onClick')} style={{width: size, height: size}} src={photoUrl || ''} alt={label} />
      </Tooltip>
    );
  }

  return <div></div>;
}

export default ChipPuestoView;
