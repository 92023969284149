import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import DepartamentoAutocompleteFormItem from 'src/view/departamento/autocomplete/DepartamentoAutocompleteFormItem';
import ModeloFormularioAutocompleteFormItem from 'src/view/modeloFormulario/autocomplete/ModeloFormularioAutocompleteFormItem';
import NivelPuestoAutocompleteFormItem from 'src/view/nivelPuesto/autocomplete/NivelPuestoAutocompleteFormItem';
import InputColorFormItem from 'src/view/shared/form/items/InputFormColorItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import MultiCheckboxFormItem from 'src/view/shared/form/items/MultiCheckboxFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';
const schema = yup.object().shape({
  nombre: yupFormSchemas.string(i18n('entities.puesto.fields.nombre'), {
    required: true,
  }),
  color: yupFormSchemas.string(i18n('entities.puesto.fields.color'), {
    required: false,
  }),
  descripcion: yupFormSchemas.string(i18n('entities.puesto.fields.descripcion'), {}),
  modeloEvaluacion: yupFormSchemas.relationToOne(i18n('entities.puesto.fields.modeloEvaluacion'), {}),
  departamento: yupFormSchemas.relationToOne(i18n('entities.puesto.fields.departamento'), {}),
  nivelPuesto: yupFormSchemas.relationToOne(i18n('entities.puesto.fields.nivelPuesto'), {
    required: true,
  }),
  restricciones: yupFormSchemas.generic(i18n('entities.puesto.fields.restricciones')),
  laborHour: yupFormSchemas.integer(i18n('entities.puesto.fields.labolHour'), {}),
});

function PuestoForm(props) {
  // Hard-coded, en el futuro se podria obtener de la base de datos
  const restriccionesDefault = [
    {
      id: 'nivelIgualInferior',
      label: 'Sólo puede ver usuarios pertenecientes a un puesto de igual o inferior nivel',
      value: false,
      extra: {},
    },
    {
      id: 'mismaUbicacion',
      label: 'Sólo puede ver usuarios de su misma ubicación',
      value: false,
      extra: {},
    },
    {
      id: 'mismoDepartamento',
      label: 'Sólo puede ver usuarios de su mismo departamento',
      value: false,
      extra: {},
    },
    {
      id: 'mismoTeam',
      label: 'Sólo puede ver usuarios de su mismo team',
      value: false,
      extra: {},
    },
    {
      id: 'mismoNivelOtraUbicacion',
      label: 'Puede ver a los usuarios de su mismo nivel, a pesar de otras restricciones',
      value: false,
      extra: {},
    },
  ];
  const [initialValues] = useState(() => {
    const record = props.record || {};

    // Esto permite mayor flexibilidad en el futuro cuando se agreguen o modifiquen restricciones
    const restricciones = restriccionesDefault.map((restriccion) => {
      const restriccionRecord = record.restricciones?.find((restriccionRecord) => restriccionRecord.id === restriccion.id);
      restriccion.value = restriccionRecord?.value || restriccion.value;
      restriccion.extra = restriccionRecord?.extra || restriccion.extra;
      return restriccion;
    });

    return {
      nombre: record.nombre,
      color: record.color,
      descripcion: record.descripcion,
      modeloEvaluacion: record.modeloEvaluacion,
      departamento: record.departamento,
      nivelPuesto: record.nivelPuesto,
      restricciones: restricciones,
      laborHour: record.laborHour,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
              overflowY: 'auto',
              marginBottom: '20px',
            } as any
          }>
          <Grid spacing={1} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="nombre"
                label={i18n('entities.puesto.fields.nombre')}
                required={true}
                disabled={props.disabled}
                autoFocus
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputColorFormItem
                name="color"
                label={i18n('entities.puesto.fields.color')}
                required={false}
                disabled={props.disabled}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <TextAreaFormItem
                name="descripcion"
                label={i18n('entities.puesto.fields.descripcion')}
                disabled={props.disabled}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="laborHour"
                disabled={props.disabled}
                label={i18n('entities.puesto.fields.laborHour')}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <ModeloFormularioAutocompleteFormItem
                name="modeloEvaluacion"
                label={i18n('entities.puesto.fields.modeloEvaluacion')}
                required={false}
                showCreate={!props.modal}
                disabled={props.disabled}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <DepartamentoAutocompleteFormItem
                name="departamento"
                label={i18n('entities.puesto.fields.departamento')}
                required={false}
                showCreate={!props.modal}
                disabled={props.disabled}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <NivelPuestoAutocompleteFormItem
                name="nivelPuesto"
                label={i18n('entities.puesto.fields.nivelPuesto')}
                required={true}
                showCreate={!props.modal}
                disabled={props.disabled}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <MultiCheckboxFormItem
                name="restricciones"
                label={i18n('entities.puesto.fields.restricciones')}
                required={false}
                defaultValue={initialValues.restricciones}
                disabled={props.disabled}
              />
            </Grid>
          </Grid>
        </form>
        <FormButtons
          style={{
            flexDirection: modal ? 'row-reverse' : undefined,
          }}>
          {!props.disabled && (
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={saveLoading}
                type="button"
                onClick={form.handleSubmit(onSubmit)}
                startIcon={<SaveIcon />}
                size="small">
                {i18n('common.save')}
              </Button>
              <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
                {i18n('common.reset')}
              </Button>
              {props.onCancel ? (
                <Button
                  disabled={saveLoading}
                  onClick={() => props.onCancel()}
                  type="button"
                  startIcon={<CloseIcon />}
                  size="small">
                  {i18n('common.cancel')}
                </Button>
              ) : null}
            </>
          )}
        </FormButtons>
      </FormProvider>
    </FormWrapper>
  );
}

export default PuestoForm;
