import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import actions from 'src/modules/recurso/form/recursoFormActions';
import selectors from 'src/modules/recurso/form/recursoFormSelectors';
import {getHistory} from 'src/modules/store';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import RecursoForm from 'src/view/recurso/form/RecursoForm';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';

function RecursoFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  const record = useSelector(selectors.selectRecord);

  const isEditing = Boolean(props.record?.id || match.params.id);
  const title = isEditing ? i18n('entities.recurso.edit.title') : i18n('entities.recurso.new.title');

  useEffect(() => {
    dispatch(actions.doInit(props.record?.id || match.params.id));
    setDispatched(true);
  }, [dispatch, match.params.id]);

  const doSubmit = (id, data) => {
    if (isEditing) {
      dispatch(actions.doUpdate(id, data, props.edited));
    } else {
      dispatch(actions.doCreate(data, props.created));
    }
  };

  console.log('🚀 ~ file: RecursoFormPage.tsx ~ line 71 ~ RecursoFormPage ~ props.tareasVinculadas', props.tareasVinculadas);

  const closeDrawer = () =>
    dispatch(
      layoutActions.doVisibleDrawer({
        drawerId: 'DrawerRecursoFormPage',
        drawerVisible: false,
        drawerChanged: moment().unix(),
        drawerUpdateComponent: 'RecursoListPage',
      }),
    );

  return (
    <>
      {!props.drawer && !props.disabled && (
        <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.recurso.menu'), '/recurso'], [title]]} />
      )}

      {!props.disabled && (
        <PageTitle sx={{fontSize: '1.4em', marginBottom: '8px', marginTop: '-4px'}}>
          <span
            style={{
              borderRadius: 50,
              border: '1px solid gray',
              backgroundColor: '#f0f0f0',
              padding: '0 10px',
              marginLeft: 24,
              fontSize: '0.7em',
            }}>
            {title}
          </span>
        </PageTitle>
      )}

      <ContentWrapper>
        {!props.disabled && (
          <PageTitle sx={{fontSize: '1.4em'}}>
            {props.mainTitle && <span style={{fontSize: '1.1em'}}>{props.mainTitle}</span>}
          </PageTitle>
        )}

        {initLoading && <Spinner />}

        {dispatched && !initLoading && (
          <RecursoForm
            tareasVinculadas={props.tareasVinculadas}
            disabled={props.record?.validado || props.disabled}
            drawer={props.drawer}
            saveLoading={saveLoading}
            initLoading={initLoading}
            record={record}
            isEditing={isEditing}
            onSubmit={doSubmit}
            onCancel={() => (props.drawer ? closeDrawer() : getHistory().push('/recurso'))}
          />
        )}
      </ContentWrapper>
    </>
  );
}

export default RecursoFormPage;
