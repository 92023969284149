import moment from 'moment-timezone';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import RecursoListActions from 'src/modules/recurso/list/recursoListActions';
import RecursoService from 'src/modules/recurso/recursoService';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'RECURSO_FORM';

const recursoFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: recursoFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await RecursoService.find(id);
      }

      dispatch({
        type: recursoFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: recursoFormActions.INIT_ERROR,
      });

      getHistory().push('/recurso');
    }
  },

  doCreate:
    (values, created: any = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: recursoFormActions.CREATE_STARTED,
        });

        const recurso = await RecursoService.create(values);

        if (created) {
          created(recurso, 'recurso');
        }

        dispatch({
          type: recursoFormActions.CREATE_SUCCESS,
        });

        Message.success(i18n('entities.recurso.create.success'));

        // getHistory().push('/recurso');

        dispatch(RecursoListActions.doFetchCurrentFilter());

        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerRecursoFormPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
            drawerUpdateComponent: 'PendienteListPage',
          }),
        );

        return recurso;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: recursoFormActions.CREATE_ERROR,
        });
      }
    },

  doUpdate:
    (id, values, edited: any = null) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: recursoFormActions.UPDATE_STARTED,
        });

        const recurso = await RecursoService.update(id, values);

        if (edited) {
          edited(id, recurso);
        }

        dispatch({
          type: recursoFormActions.UPDATE_SUCCESS,
        });

        Message.success(i18n('entities.recurso.update.success'));

        // getHistory().push('/recurso');

        dispatch(RecursoListActions.doFetchCurrentFilter());

        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerRecursoFormPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
          }),
        );
        return recurso;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: recursoFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default recursoFormActions;
