import {yupResolver} from '@hookform/resolvers/yup';
import {Add} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from '@mui/material';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import historialInventarioEnumerators from 'src/modules/historialInventario/historialInventarioEnumerators';
import HistorialInventarioService from 'src/modules/historialInventario/historialInventarioService';
import recursoEnumerators from 'src/modules/recurso/recursoEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import TipoActivoService from 'src/modules/tipoActivo/tipoActivoService';
import {customButtonStyle} from 'src/theme/schemes/CustomTheme';
import InventarioAutocompleteFormItem from 'src/view/inventario/autocomplete/InventarioAutocompleteFormItem';
import PuestoAutocompleteFormItem from 'src/view/puesto/autocomplete/PuestoAutocompleteFormItem';
import ServicioAutocompleteFormItem from 'src/view/servicio/autocomplete/ServicioAutocompleteFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputMultiSelectorFormItem from 'src/view/shared/form/items/InputMultiSelectorFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import Message from 'src/view/shared/message';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';
import RecursoCardAlignItemsList from './RecursoCardAlignItemsList';

const schema = yup.object().shape({
  presupuesto: yupFormSchemas.relationToOne(i18n('entities.recurso.fields.presupuesto')),

  responsableRecurso: yupFormSchemas.relationToOne(i18n('entities.recurso.fields.responsableRecurso'), {
    required: false,
  }),
  ubicacionRecurso: yupFormSchemas.relationToOne(i18n('entities.recurso.fields.ubicacionRecurso'), {
    required: false,
  }),

  ubicacionInventario: yupFormSchemas.enumerator(i18n('entities.recurso.fields.ubicacionInventario'), {
    required: false,
    options: historialInventarioEnumerators.tipoMovimiento,
  }),
  descripcion: yupFormSchemas.string(i18n('entities.recurso.fields.descripcion'), {
    required: true,
  }),
  validado: yupFormSchemas.string(i18n('entities.recurso.fields.validado'), {
    required: false,
  }),
  tipo: yupFormSchemas.enumerator(i18n('entities.recurso.fields.tipo'), {
    required: true,
    options: recursoEnumerators.tipo,
  }),
  tipoActivoId: yupFormSchemas.string(i18n('entities.activo.fields.tipoActivo'), {
    required: false,
  }),
  cuentasContables: yupFormSchemas.relationToMany(i18n('entities.recurso.fields.cuentasContables'), {}),
  fechaPago: yupFormSchemas.date(i18n('entities.recurso.fields.fechaPago'), {}),
  cantidad: yupFormSchemas.integer(i18n('entities.recurso.fields.cantidad'), {
    required: true,
  }),
  fechaEstimadaUso: yupFormSchemas.date(i18n('entities.recurso.fields.fechaEstimadaUso'), {
    required: false,
  }),

  total: yupFormSchemas.integer(i18n('entities.recurso.fields.total'), {
    required: false,
  }),
  totalActualizado: yupFormSchemas.integer(i18n('entities.recurso.fields.totalActualizado'), {
    required: false,
  }),
  ejecutor: yupFormSchemas.relationToOne(i18n('entities.recurso.fields.ejecutor'), {}),
  repuesto: yupFormSchemas.relationToOne(i18n('entities.recurso.fields.repuesto'), {}),
  servicio: yupFormSchemas.relationToOne(i18n('entities.recurso.fields.servicio'), {}),
  perfilEmpleado: yupFormSchemas.relationToOne(i18n('entities.recurso.fields.perfilEmpleado'), {}),
  puesto: yupFormSchemas.relationToOne(i18n('entities.recurso.fields.puesto'), {}),
  fotos: yupFormSchemas.images(i18n('entities.recurso.fields.fotos'), {
    required: false,
  }),
  documentos: yupFormSchemas.files(i18n('entities.recurso.fields.documentos'), {}),
  tareasVinculadas: yupFormSchemas.relationToMany(i18n('entities.recurso.fields.tareasVinculadas'), {
    required: false,
  }),
  subtareasVinculadas: yupFormSchemas.relationToMany(i18n('entities.recurso.fields.subtareasVinculadas'), {}),
  profitMargin: yupFormSchemas.integer(i18n('entities.recurso.fields.profitMargin'), {}),
});

function RecursoForm(props) {
  const record = props.record || {};
  const [confirm, setConfirm] = useState(false);
  const [stockDisponible, setStockDisponible]: any = useState(true);
  const [dispatched, setDispatched] = useState(false);
  const [tiposActivoOptions, setTiposActivoOptions] = useState([]);
  const [selectedTipoActivoId, setSelectedTipoActivoId] = useState(record.tipoActivoId || '');

  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      ubicacionInventario: record.ubicacionInventario || 'Ubicacion',
      responsableRecurso: record.responsableRecurso,
      ubicacionRecurso: record.ubicacionRecurso,
      presupuesto: record.presupuesto || props.presupuesto,
      descripcion: record.descripcion,
      validado: record.validado,
      tipo: record.tipo,
      tipoActivoId: record.tipoActivoId || '',
      cuentasContables: record.cuentasContables || [],
      fechaPago: record.fechaPago ? moment(record.fechaPago, 'YYYY-MM-DD') : null,
      cantidad: record.cantidad,
      fechaEstimadaUso: record.fechaEstimadaUso ? moment(record.fechaEstimadaUso, 'YYYY-MM-DD') : null,
      total: record.total,
      totalActualizado: record.totalActualizado,
      ejecutor: record.ejecutor,
      repuesto: record.repuesto,
      servicio: record.servicio,
      perfilEmpleado: record.perfilEmpleado,
      puesto: record.puesto,
      fotos: record.fotos || [],
      documentos: record.documentos || [],
      tareasVinculadas: record.tareasVinculadas || props.tareasVinculadas || [],
      subtareasVinculadas: record.subtareasVinculadas || [],
      profitMargin: record.profitMargin,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  // Load tipo activo options
  useEffect(() => {
    const loadTiposActivo = async () => {
      try {
        console.log('Cargando tipos de activo...');
        const response = await TipoActivoService.listAutocomplete('', 100);

        // Map the response to the format expected by SelectFormItem
        const processedResponse = response.map((item) => ({
          ...item,
          value: String(item.value), // Convertir value a string para asegurar consistencia
        }));

        console.log('Tipos de activo cargados:', processedResponse.length);
        setTiposActivoOptions(processedResponse);
      } catch (error) {
        console.error('Error al cargar tipos de activo:', error);
        setTiposActivoOptions([]);
      }
    };

    loadTiposActivo();
  }, []);

  const onSubmit = (values) => {
    setConfirm(false);

    if (!values.total || values.total <= 0) {
      Message.error('El total de tus recursos a añadir es 0, por favor verifica tus recursos...');
      return;
    }

    if (values.tipo === 'Inventario') {
      if (values.ubicacionInventario === 'Responsable' && !values.responsableRecurso) {
        Message.error('No has asignado responsable del recurso');
        return;
      }
      if (values.ubicacionInventario === 'Ubicacion' && !values.ubicacionRecurso) {
        Message.error('No has asignado la ubicación del recurso');
        return;
      }
      if (stockDisponible < form.watch('cantidad')) {
        Message.error(`No posees suficiente stock. Cantidad disponible: ${stockDisponible}`);
        form.setValue('cantidad', stockDisponible);
        return;
      }
    }

    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const doConfirm = () => {
    setConfirm(true);
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  const {saveLoading, modal} = props;

  useEffect(() => {
    updateCantidad();
  }, [form.watch('cantidad')]);

  // useEffect(() => {
  //   updateTotal();
  // }, [
  //   form.watch('cantidad'),
  //   form.watch('repuesto')?.id,
  //   form.watch('ejecutor')?.id,
  //   form.watch('servicio')?.id,
  //   form.watch('perfilEmpleado')?.id,
  // ]);

  const updateCantidad = () => {
    if (form.watch('tipo') && form.watch('tipo') !== 'Inventario') return;

    if (form.watch('cantidad') > 0 && form.watch('tipo') === 'Inventario' && form.watch('repuesto')) {
      if (!stockDisponible) {
        Message.error(`No posees suficiente stock. Cantidad disponible: 0`);
        form.setValue('cantidad', 0);
        return;
      }

      if (stockDisponible !== true) {
        if (stockDisponible === 0) {
          Message.error(`No posees suficiente stock. Cantidad disponible: 0`);
          form.setValue('cantidad', 0);
        }

        if (stockDisponible < form.watch('cantidad')) {
          Message.error(`No posees suficiente stock. Cantidad disponible: ${stockDisponible}`);
          form.setValue('cantidad', stockDisponible);
        }
      }
    }
  };

  const updateTotal = () => {
    let total = 0;

    if (form.getValues().tipo === 'Inventario') {
      total = form.getValues().repuesto?.activo?.costoCompra * parseInt(form.getValues().cantidad);
    }

    if (form.getValues().tipo === 'Recurso Humano') {
      total = form.getValues().ejecutor?.perfilEmpleado?.turnoLaboral?.costoHora * parseInt(form.getValues().cantidad);
    }

    if (form.getValues().tipo === 'Servicios') {
      total = form.getValues().servicio?.costoHora * parseInt(form.getValues().cantidad);
    }

    if (form.watch('totalActualizado')) {
      total = form.watch('totalActualizado') * parseInt(form.getValues().cantidad);
    }

    form.setValue('total', total || 0);
  };

  useEffect(() => {
    onChangeUbicacionRecurso(false);
    onChangeResponsableRecurso(false);
  }, []);

  const onChangeUbicacionRecurso = async (checkStock = true) => {
    if (form.watch('ubicacionRecurso')) {
      HistorialInventarioService.list(
        {
          ubicacion: form.watch('ubicacionRecurso')?.id,
          inventario: form.watch('repuesto')?.id,
        },
        'createdAt_DESC',
        1,
        0,
      ).then((response) => {
        if (response.rows.length > 0) {
          setStockDisponible(response.rows[0].stockFinal);

          if (checkStock) {
            if (form.watch('cantidad') > response.rows[0].stockFinal) {
              form.setValue('cantidad', response.rows[0].stockFinal);
            }

            if (form.watch('cantidad') < 0) {
              form.setValue('cantidad', 0);
            }
          }
        } else {
          if (checkStock) {
            setStockDisponible(false);
            form.setValue('cantidad', 0);
          }
        }

        setDispatched(true);
      });
    } else {
      if (checkStock) {
        form.setValue('cantidad', 0);
        setStockDisponible(false);
        setDispatched(true);
      }
    }
  };

  const onChangeResponsableRecurso = async (checkStock = true) => {
    if (form.watch('responsableRecurso')) {
      HistorialInventarioService.list(
        {
          responsable: form.watch('responsableRecurso')?.id,
          inventario: form.watch('repuesto')?.id,
        },
        'createdAt_DESC',
        1,
        0,
      ).then((response) => {
        if (response.rows.length > 0) {
          setStockDisponible(response.rows[0].stockFinal);

          if (checkStock) {
            if (form.watch('cantidad') > response.rows[0].stockFinal) {
              form.setValue('cantidad', response.rows[0].stockFinal);
            }

            if (form.watch('cantidad') < 0) {
              form.setValue('cantidad', 0);
            }
          }
        } else {
          if (checkStock) {
            setStockDisponible(false);
            form.setValue('cantidad', 0);
          }
        }

        setDispatched(true);
      });
    } else {
      if (checkStock) {
        form.setValue('cantidad', 0);
        setStockDisponible(false);
        setDispatched(true);
      }
    }
  };

  const onError = (errors) => {
    console.log('Errores de validación:', errors);
  };

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, onError)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
            paddingBottom: '16px',
          }}>
          <Grid container columnGap={3}>
            {/* <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <h1>{form.watch('descripcion')}</h1>
            </Grid> */}

            {(form.watch('ejecutor') ||
              form.watch('servicio') ||
              form.watch('repuesto') ||
              form.watch('perfilEmpleado') ||
              form.watch('puesto')) &&
              form.watch('tipo') &&
              form.watch('cantidad') && (
                <Grid item lg={11} md={11} sm={12} xs={12}>
                  <RecursoCardAlignItemsList
                    record={{
                      ...form.getValues(),
                      total: form.watch('total'),
                    }}
                  />
                </Grid>
              )}

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <SelectFormItem
                menuPortalTarget={document.body}
                name="tipo"
                disabled={props.disabled}
                label={i18n('entities.recurso.fields.tipo')}
                onChange={(value) => {
                  form.setValue('ejecutor', null);
                  form.setValue('repuesto', null);
                  form.setValue('servicio', null);
                  form.setValue('perfilEmpleado', null);
                  form.setValue('puesto', null);

                  // Reset tipoActivoId if changing from Inventario to another type
                  if (value !== 'Inventario') {
                    form.setValue('tipoActivoId', '');
                    setSelectedTipoActivoId('');
                  }

                  // updateTotal();
                  updateCantidad();
                }}
                options={recursoEnumerators.tipo.map((value) => ({
                  value,
                  label: i18n(`entities.recurso.enumerators.tipo.${value}`),
                }))}
                required={true}
              />
            </Grid>
            {/* {form.watch('tipo') === 'Recurso Humano' && (
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <PerfilEmpleadoAutocompleteFormItem
                  name="perfilEmpleado"
                  disabled={props.disabled}
                  onChange={(value) => {
                    form.setValue('descripcion', value.label);
                    // console.log('🚀 ~ file: RecursoForm.tsx ~ line 238 ~ RecursoForm ~ value', value);
                    form.setValue('total', value.laborHour);
                  }}
                  label={i18n('entities.recurso.fields.perfilEmpleado')}
                  required={false}
                  showCreate={!props.modal}
                  // mode="multiple"
                />
              </Grid>
            )} */}
            {form.watch('tipo') === 'Recurso Humano' && (
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <PuestoAutocompleteFormItem
                  name="puesto"
                  disabled={props.disabled}
                  onChange={(value) => {
                    form.setValue('descripcion', value.label);
                    form.setValue('total', value.laborHour);
                  }}
                  label={i18n('entities.recurso.fields.puesto')}
                  required={false}
                  showCreate={!props.modal}
                  // mode="multiple"
                />
              </Grid>
            )}

            {form.watch('tipo') === 'Inventario' && (
              <>
                <Grid item lg={7} md={7} sm={12} xs={12} container spacing={2}>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="tipo-activo-label">{i18n('entities.activo.fields.tipoActivo')}</InputLabel>
                      <Select
                        labelId="tipo-activo-label"
                        id="tipo-activo-select"
                        value={selectedTipoActivoId || ''}
                        onChange={(event) => {
                          const value = event.target.value;
                          console.log('Tipo de activo seleccionado:', value, 'tipo:', typeof value);

                          // Actualizar el estado local
                          setSelectedTipoActivoId(value);

                          // Sincronizar con form para validación y envío
                          form.setValue('tipoActivoId', value, {shouldValidate: true});

                          // Limpiar el recurso seleccionado
                          form.setValue('repuesto', null, {shouldValidate: true});
                        }}
                        label={i18n('entities.activo.fields.tipoActivo')}
                        disabled={props.disabled}>
                        <MenuItem value="">{i18n('common.all')}</MenuItem>
                        {tiposActivoOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={7} md={7} sm={12} xs={12}>
                    <InventarioAutocompleteFormItem
                      name="repuesto"
                      disabled={props.disabled}
                      onChange={(value) => {
                        form.setValue('descripcion', value.label);
                        form.setValue('cantidad', form.watch('repuesto')?.stockDisponible);
                        form.setValue('total', value.record?.activo?.costoCompra);

                        // updateTotal();
                        updateCantidad();
                      }}
                      label={i18n('entities.recurso.fields.repuesto')}
                      required={false}
                      showCreate={!props.modal}
                      tipoActivoId={selectedTipoActivoId || ''}
                      // mode="multiple"
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {form.watch('tipo') === 'Servicios' && (
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <ServicioAutocompleteFormItem
                  name="servicio"
                  disabled={props.disabled}
                  label={i18n('entities.recurso.fields.servicio')}
                  required={false}
                  showCreate={!props.modal}
                  onChange={(value) => {
                    form.setValue('descripcion', value.label);
                    form.setValue('total', value.record?.costoHora);
                  }}
                  // mode="multiple"
                />
              </Grid>
            )}

            <Grid item lg={props.drawer ? 11 : 8} md={8} sm={12} xs={12}>
              <InputFormItem
                name="descripcion"
                disabled={props.disabled}
                label={i18n('entities.recurso.fields.descripcion')}
                required={true}
                autoFocus
              />
            </Grid>

            {form.watch('tipo') === 'Inventario' && (
              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                <Grid item lg={7} md={8} sm={12} xs={12}>
                  <InputMultiSelectorFormItem
                    label={i18n('entities.recurso.fields.ubicacionInventario')}
                    required={true}
                    options={[
                      {
                        label: 'Ubicación',
                        value: 'Ubicacion',
                        color: 'blue',
                      },
                      {
                        label: 'Responsable',
                        value: 'Responsable',
                        color: 'blue',
                      },
                    ]}
                    disabled={props.disabled}
                    name="ubicacionInventario"
                  />
                </Grid>

                {form.watch('ubicacionInventario') === 'Ubicacion' && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <UbicacionAutocompleteFormItem
                      name="ubicacionRecurso"
                      label={i18n('entities.recurso.fields.ubicacionRecurso')}
                      onChange={() => onChangeUbicacionRecurso()}
                      disabled={props.disabled}
                      required={false}
                      showCreate={!props.modal}
                    />
                  </Grid>
                )}

                {form.watch('ubicacionInventario') === 'Responsable' && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <UserAutocompleteFormItem
                      name="responsableRecurso"
                      label={i18n('entities.recurso.fields.responsableRecurso')}
                      onChange={() => onChangeResponsableRecurso()}
                      disabled={props.disabled}
                      required={false}
                      showCreate={!props.modal}
                    />
                  </Grid>
                )}

                <Grid container>
                  <Grid lg={12} xs={12} container alignItems="center">
                    <h1 style={{fontSize: 28}}>
                      Stock Disponible:{' '}
                      {new Intl.NumberFormat('es-CL', {
                        currency: 'CLP',
                        style: 'decimal',
                      }).format(stockDisponible)}
                    </h1>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {(form.watch('puesto') || form.watch('servicio') || form.watch('repuesto')) && form.watch('tipo') && (
              <>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <InputNumberFormItem
                    name="cantidad"
                    disabled={props.disabled}
                    label={i18n('entities.recurso.fields.cantidad')}
                    required={false}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="total"
                    disabled={props.disabled}
                    label={
                      form.watch('puesto') ? i18n('entities.puesto.fields.laborHour') : i18n('entities.recurso.fields.total')
                    }
                    required={false}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <Typography variant="caption" fontSize={'0.8em'}>
                    Subtotal
                  </Typography>
                  <Typography variant="h6">
                    ${' '}
                    {new Intl.NumberFormat('es-CL', {
                      currency: 'CLP',
                      style: 'decimal',
                    }).format(form.watch('total') * form.watch('cantidad'))}
                  </Typography>
                </Grid>

                {/* <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="totalActualizado"
                    disabled={props.disabled}
                    label={i18n('entities.recurso.fields.totalActualizado')}
                    required={false}
                    onChange={() => {
                      // updateTotal();
                      updateCantidad();
                    }}
                  />
                  <InputNumberFormItem
                    name="profitMargin"
                    disabled={props.disabled}
                    label={i18n('entities.recurso.fields.profitMargin')}
                    required={false}
                  />
                </Grid> */}
              </>
            )}

            {/* <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <DatePickerFormItem
                name="fechaEstimadaUso"
                disabled={props.disabled}
                label={i18n('entities.recurso.fields.fechaEstimadaUso')}
                required={false}
              />
            </Grid>

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <ImagesFormItem
                name="fotos"
                disabled={props.disabled}
                label={i18n('entities.recurso.fields.fotos')}
                required={false}
                storage={Storage.values.recursoFotos}
                max={undefined}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <FilesFormItem
                name="documentos"
                disabled={props.disabled}
                label={i18n('entities.recurso.fields.documentos')}
                required={false}
                storage={Storage.values.recursoDocumentos}
                max={undefined}
                formats={undefined}
              />
            </Grid> */}
          </Grid>
        </form>
        {/* {!props.disabled && (
          <Box style={{marginTop: 20}}>
            <Button
              style={{
                ...customButtonStyle,
                width: '100%',
                backgroundColor: green[500],
              }}
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={() => {
                doConfirm();
                form.setValue('validado', true);
              }}
              startIcon={<CheckOutlined />}
              size="large">
              {i18n('common.validate')}
            </Button>
          </Box>
        )} */}

        {!props.disabled && (
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              style={{
                width: 300,
                ...customButtonStyle,
              }}
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={() => {
                form.setValue('validado', false);
                doConfirm();
              }}
              startIcon={<Add />}
              size="large">
              {props.isEditing ? i18n('common.edit') : i18n('common.add')}
            </Button>

            <Button
              style={{
                ...customButtonStyle,
                fontSize: '0.8em',
              }}
              disabled={saveLoading}
              onClick={onReset}
              type="button"
              startIcon={<UndoIcon />}
              size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                style={{
                  ...customButtonStyle,
                  fontSize: '0.8em',
                }}
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        )}
      </FormProvider>

      {confirm && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={form.handleSubmit(onSubmit, onError)}
          onClose={() => doCloseConfirm()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </FormWrapper>
  );
}

export default RecursoForm;
